import { gql } from '@apollo/client';
import { PatientListInfoShared } from '../fragments';

export default gql`
  query patients(
    $accountId: ID
    $partnerOrganizationId: ID
    $partnerLocationId: ID
    $scanReady: Boolean
    $withoutOrders: Boolean
    $activeAccount: Boolean
    $withoutScan: Boolean
  ) {
    patients(
      accountId: $accountId
      partnerOrganizationId: $partnerOrganizationId
      partnerLocationId: $partnerLocationId
      scanReady: $scanReady
      withoutOrders: $withoutOrders
      activeAccount: $activeAccount
      withoutScan: $withoutScan
    ) {
      ...PatientListInfoShared
    }
  }
  ${PatientListInfoShared}
`;
