import { gql } from '@apollo/client';
import { ProductionOrderListInfo } from '../fragments';

export default gql`
  query productionOrders($status: String) {
    productionOrders(status: $status) {
      ...ProductionOrderListInfo
    }
  }
  ${ProductionOrderListInfo}
`;
