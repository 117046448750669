import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from '@headlessui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import { dobFormatter } from '../../../transforms';
import ShowStatus from '../ShowStatus/ShowStatus';

const NOT_AVAILABLE = 'Not Available';

function ShowPatientDetails({ patient, showTypeLabel = false }) {
  return (
    <li
      key={patient.id}
      className="flex items-center justify-between gap-x-6 py-4"
    >
      <div className="min-w-0">
        <div className="flex items-start gap-x-3">
          <p className="font-semibold text-black text-sm capitalize leading-6">
            {patient?.firstName} {patient?.lastName}
          </p>
          {showTypeLabel && <ShowStatus state="Patient" size="small" />}
        </div>
        <div className="mt-1 flex flex-col items-start gap-x-2 text-black/70 text-xs leading-5">
          <p className="whitespace-nowrap">
            DOB: {patient.dob ? dobFormatter(patient.dob) : NOT_AVAILABLE}
          </p>
        </div>
      </div>
      <div className="flex flex-none items-center gap-x-4">
        <Menu as="div" className="relative flex-none">
          <MenuButton className="-m-2.5 block p-2.5 text-black/70 hover:text-black">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon className="size-5" aria-hidden="true" />
          </MenuButton>
          <Transition
            as="div"
            className="absolute right-0 z-10 mt-2 w-32 origin-top-right rounded-md bg-black py-2 shadow-lg ring-1 ring-gray-900/5 transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[enter]:scale-100 data-[leave]:scale-95 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
          >
            <MenuItems className="py-1">
              <MenuItem>
                <NavLink
                  to={`/customers/patients/${patient?.slug}`}
                  className="block px-3 py-1 text-sm text-white leading-6"
                >
                  View
                </NavLink>
              </MenuItem>
              <MenuItem>
                <NavLink
                  to={`/customers/patients/${patient?.slug}/edit`}
                  className="block px-3 py-1 text-sm text-white leading-6"
                >
                  Edit
                </NavLink>
              </MenuItem>
            </MenuItems>
          </Transition>
        </Menu>
      </div>
    </li>
  );
}

ShowPatientDetails.propTypes = {
  patient: PropTypes.object.isRequired,
  showTypeLabel: PropTypes.bool,
};

export default ShowPatientDetails;
