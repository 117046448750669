import { gql } from '@apollo/client';
import { ReferralListInfoShared } from '../fragments';

export default gql`
  query referrals(
    $partnerOrganizationId: ID
    $partnerLocationId: ID
    $state: String
    $scanReady: Boolean
    $accountId: ID
  ) {
    referrals(
      partnerOrganizationId: $partnerOrganizationId
      partnerLocationId: $partnerLocationId
      state: $state
      scanReady: $scanReady
      accountId: $accountId
    ) {
      ...ReferralListInfoShared
    }
  }
  ${ReferralListInfoShared}
`;
