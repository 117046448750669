import { gql } from '@apollo/client';

export default gql`
  query shippoWebhooks($productionOrderId: ID, $event: String) {
    shippoWebhooks(productionOrderId: $productionOrderId, event: $event) {
      id
      event
      trackingStatus
      trackingNumber
      trackingUrl
      createdAt
    }
  }
`;
