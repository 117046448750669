import { Menu, MenuButton, MenuItems, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function SelectMenu({ children, title = 'Actions' }) {
  return (
    <Menu as="div" className="inline-block text-left">
      <div>
        <MenuButton className="group inline-flex w-full justify-center gap-x-1.5 rounded-md bg-rcprimary-400 px-3 py-1.5 font-medium text-sm text-white outline outline-2 outline-white/0 outline-offset-0 hover:bg-rcprimary-400/0 hover:text-rcprimary-400 hover:outline-rcprimary-400">
          {title}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-white group-hover:text-rcprimary-400"
            aria-hidden="true"
          />
        </MenuButton>
      </div>

      <Transition
        as="div"
        className="absolute right-0 z-10 mt-2 w-80 origin-top-right divide-y divide-white/15 rounded-md border border-rcprimary-100/30 bg-black/90 py-1 shadow-lg ring-1 ring-black/5 transition duration-100 ease-out focus:outline-none data-[closed]:scale-95 data-[enter]:scale-100 data-[leave]:scale-95 data-[closed]:opacity-0 data-[enter]:opacity-100 data-[leave]:opacity-0"
      >
        <MenuItems>{children}</MenuItems>
      </Transition>
    </Menu>
  );
}

SelectMenu.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default SelectMenu;
