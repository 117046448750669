import { gql } from '@apollo/client';

const ReferralListInfoShared = gql`
  fragment ReferralListInfoShared on Referral {
    id
    slug
    state
    consumedAt
    sentAt
    source
    partnerLocation {
      id
      name
      slug
    }
    userAttributes {
      id
      firstName
      lastName
      slug
    }
    patientAttributes {
      id
      firstName
      lastName
      slug

    }
    createdAt
  }
`;

export default ReferralListInfoShared;
