import { gql } from '@apollo/client';

const UserListInfoShared = gql`
  fragment UserListInfoShared on User {
    id
    firstName
    lastName
    email
    phone
    slug
    state
    withoutPlan
    partnerLocation {
      id
      name
      slug
    }
    account {
      id
      accountNumber
      slug
    }
    lastLoginAt
    createdAt
  }
`;

export default UserListInfoShared;
