import { useAccount, useCart, useLocale } from '@retainerclub/hooks';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import ShopProductsModal from './ShopProductsModal';

function ShopProducts({ priceBookProducts = [] }) {
  const [currentPriceBookProduct, setCurrentPriceBookProduct] = useState(null);
  const [open, setOpen] = useState(false);
  const [planInCart, setPlanInCart] = useState(false);
  const { locale } = useLocale();
  const { currentPatient } = useAccount();
  const { cart } = useCart();
  const patientHasPlan = currentPatient?.hasPlan;

  function handleOpen(product) {
    setCurrentPriceBookProduct(product);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
    setCurrentPriceBookProduct(null);
  }

  useEffect(() => {
    cart.cartItemsAttributes.some((cartItem) => {
      return (
        cartItem.product.productType === 'plan' &&
        cartItem.patientId === currentPatient.id
      );
    })
      ? setPlanInCart(true)
      : setPlanInCart(false);
  }, [cart, currentPatient.id]);

  return (
    <>
      <section
        aria-labelledby="products-heading"
        className="mx-auto max-w-7xl overflow-hidden sm:px-6 lg:px-8"
      >
        <div className="-mx-px grid grid-cols-2 border-gray-200 border-l sm:mx-0 md:grid-cols-3 lg:grid-cols-4">
          {priceBookProducts
            .filter((p) => p.product.active === true)
            .map((priceBookProduct) => (
              <div
                key={priceBookProduct.id}
                className={`border-rcgray-300 ${currentPatient?.hasPlan && priceBookProduct.product.productType === 'plan' ? 'bg-rcgray-200' : priceBookProduct.product.productType === 'plan' ? 'bg-rcprimary-50' : 'bg-rcgray-50'} group relative flex flex-col border-r border-b p-4 sm:p-6`}
              >
                {currentPatient?.hasPlan &&
                  priceBookProduct.product.productType === 'plan' && (
                    <div className="absolute top-8 right-0 z-10 w-2/3 rounded-l-lg bg-rcprimary-400 p-4 font-bold text-white shadow-md sm:top-28">
                      Currently Enrolled
                    </div>
                  )}
                <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-rcgray-200 group-hover:opacity-75">
                  <img
                    src={priceBookProduct.product.featuredImage}
                    alt={priceBookProduct.product.name}
                    className="size-full object-cover object-center"
                  />
                </div>
                <div className="flex grow flex-col pt-8 pb-4 text-center">
                  <h3 className="font-medium text-base text-gray-900">
                    <button
                      type="button"
                      disabled={
                        (patientHasPlan || planInCart) &&
                        priceBookProduct.product.productType === 'plan'
                      }
                      className={
                        (patientHasPlan || planInCart) &&
                        priceBookProduct.product.productType === 'plan'
                          ? 'cursor-not-allowed'
                          : ''
                      }
                      onClick={() => handleOpen(priceBookProduct)}
                    >
                      <span aria-hidden="true" className="absolute inset-0" />
                      {priceBookProduct.product.name}
                    </button>
                  </h3>
                  <div className="mt-3 flex flex-col items-center">
                    <p className="mt-1 text-gray-500 text-sm">
                      {priceBookProduct.product.description}
                    </p>
                  </div>
                  <div className="grow" />
                  <p className="mt-4 font-medium text-base text-gray-900">
                    {(
                      (priceBookProduct.overPrice
                        ? priceBookProduct.price
                        : priceBookProduct.product.price) / 100
                    ).toLocaleString(locale, {
                      style: 'currency',
                      currency: 'USD',
                    })}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </section>

      {currentPriceBookProduct && (
        <ShopProductsModal
          open={open}
          setOpen={() => handleClose()}
          priceBookProduct={currentPriceBookProduct}
        />
      )}
    </>
  );
}

ShopProducts.propTypes = {
  priceBookProducts: PropTypes.array,
};

export default ShopProducts;
